import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import PortfolioNav from '../PortfolioNav';
import NavButton from '../NavButton';
import NavButtonExpandable from '../NavButtonExpandable';
import PortfolioContext from '../PortfolioContext';
// import {ShoppingCartMenuButton} from '../ShoppingCart';
import StoryBlokContext from '../StoryBlokHooks';

import './Navigation.scss';


const Navigation = ({className='App-primary-nav'}) => {
    const location = useLocation();
    const { special_editions } = useContext(StoryBlokContext);
    const { tree } = useContext(PortfolioContext);
    const [seList, setSeList] = useState(null);
    const [primaryOpen, setPrimaryOpen] = useState(false);
    const [portfolioOpen, setPortfolioOpen] = useState(false);
    const [seOpen, setSeOpen] = useState(false);

    useEffect(() => {
        setPrimaryOpen(false);
        setPortfolioOpen(false);
        setSeOpen(false);
    }, [location]);

    useEffect(() => {
        if (!special_editions) return;
        let tmpSE = special_editions.map((se) => {
            return {
                to: `/${se.full_slug}`,
                text: se.name
            }
        })
        tmpSE.sort((se1, se2) => {
            let nA = se1.text.toUpperCase();
            let nB = se2.text.toUpperCase();
            return nA < nB ? -1 : nA > nB ? 1 : 0;
        })
        setSeList(tmpSE)
    }, [special_editions])

    const handleSeClick = () => {
        if (!seOpen) {
            setSeOpen(true);
            setPortfolioOpen(false);
        } else {
            setSeOpen(false)
        }
    }

    const handlePortClick = () => {
        if (!portfolioOpen) {
            setPortfolioOpen(true);
            setSeOpen(false);
        } else {
            setPortfolioOpen(false)
        }
    }

    if (!seList) return null;

    return (
        <div className={className}>
            <div
                className={classnames('nav-buttons', {
                    open: primaryOpen || portfolioOpen
                })}
            >
                <button
                    className="port-nav-toggle"
                    onClick={() => {
                        setPortfolioOpen(!portfolioOpen);
                        setPrimaryOpen(false);
                    }}
                >
                    Portfolios
                </button>
                <button
                    className="primary-nav-toggle"
                    onClick={() => {
                        setPrimaryOpen(!primaryOpen);
                        setPortfolioOpen(false);
                    }}
                >
                    <i className="material-icons">menu</i>
                </button>
                <nav className={classnames("portfolio-nav-list", {open: portfolioOpen})}>
                    <i
                        className={classnames(
                            'hamb-nav-close',
                            {
                                open: portfolioOpen
                            },
                            'material-icons'
                        )}
                        onClick={() => {
                            setPortfolioOpen(false);
                        }}
                    >
                        close
                    </i>

                    <PortfolioNav
                        text="The Work"
                        portTree={tree}
                        expand={true}
                        clickHand={handlePortClick}
                    />
                    <NavButtonExpandable
                        text="Special Editions"
                        navItems={seList}
                        expand={true}
                        clickHand={handleSeClick}
                    />
                </nav>
                <nav className={classnames("primary-nav-list", {open: primaryOpen})}>
                    <i
                        className={classnames(
                            'hamb-nav-close',
                            {
                                open: primaryOpen
                            },
                            'material-icons'
                        )}
                        onClick={() => {
                            setPrimaryOpen(false);
                        }}
                    >
                        close
                    </i>
                    <NavButton to="/articles" text="News & Thoughts" />
                    <NavButton to="/acquire-prints" text="Acquire Fine Art Prints" />
                    <NavButton to="/protect-these-lands" text="Protect These Lands" />
                    <NavButton to="/about" text="About Walt" />
                    <NavButton to="/contact" text="Contact Walt" />
                    {/* <NavButton to="/cart" text={<i className="material-icons">shopping_cart</i>} /> */}

                </nav>
            </div>
            <nav className='primary-nav-bar'>
                <PortfolioNav
                    text="The Work"
                    portTree={tree}
                    expand={portfolioOpen}
                    clickHand={handlePortClick}
                />
                <NavButtonExpandable
                    text="Special Editions"
                    navItems={seList}
                    expand={seOpen}
                    clickHand={handleSeClick}
                />
                <NavButton to="/articles" text="News &amp; Thoughts" />
                <NavButton to="/acquire-prints" text="Acquire Fine Art Prints" />
                <NavButton to="/protect-these-lands" text="Protect These Lands" />
                <NavButton to="/about" text="About Walt" />
                <NavButton to="/contact" text="Contact Walt" />
                {/* <ShoppingCartMenuButton /> */}
            </nav>
        </div>
    );
};

export default Navigation;
