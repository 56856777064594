import React from 'react';
import { NavLink } from 'react-router-dom';
import './PortfolioNav.scss';

const PortfolioNav = ({ text="Portfolios", portTree, expand, clickHand, level=0 }) => {

    const DispPortChildren = ({portTree}) => {
        if (!portTree.display) return null

        return (
            <>
                {!portTree.isSet && (
                    <NavLink to={`/portfolios/${portTree.slug}`} >
                        {portTree.name}
                    </NavLink>
                )}
                {portTree.isSet && <PortfolioNav text={portTree.name} portTree={portTree.children} expand={false} level={level+1} />}
            </>
        )
    };

    if (!portTree) return null;

    return (
        <nav className={`nav-list-accordian-head${level === 0 ? '' : `-${level}`}`} >
            {text}{level !== 0 ? <span>&ensp;+</span> : ''}
            <div className='nav-list-accordian-body' >
                {portTree.map((port) => {
                        return <DispPortChildren portTree={port} key={port._id} />
                })}
            </div>
        </nav>
    );
};

export default PortfolioNav;
