import React from "react";
import { NavLink } from 'react-router-dom';
import './NavButtonExpandable.scss';

const NavButtonExpandable = ({classN='nav-list-accordian-head', navItems, text='', expand=false}) => {

    return (
        <div className={classN} >
            {text}
            <div className='nav-list-accordian-body' >
                {navItems.map((navItem, idx) => {
                    if (!navItem) return null;
                    return(
                        <NavLink to={navItem.to} key={`nav-list-ab-${idx}`}>
                            {navItem.text}
                        </NavLink>
                    )
                })}
            </div>
        </div>
    )
}

export default NavButtonExpandable;
