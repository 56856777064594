import React from 'react';
import { Link } from 'react-router-dom';
import SocialIcons from '../SocialIcons';
import './Footer.scss';

const Footer = ({className='App-footer'}) => {
    return (
        <footer className={className}>
            <SocialIcons />
            <div className="footer-legal">
                <Link to="/copyright-statement">
                    Copyright © 2007-2020 Walt Thirion Photography LLC. ALL RIGHTS
                    RESERVED.
                </Link>
                &#8195;|&#8195;
                <Link to="/terms-and-conditions">Terms of Service</Link>
                &#8195;|&#8195;
                <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
        </footer>
    );
};

export default Footer;
