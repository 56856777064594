import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// const storyBlokProductionAPIKey = '55BfiPid23KrYvqLCzh8EAtt';
// const storyBlokPreviewAPIKey = 'K4Ub4FZmQQhDktQTgrdzaAtt';
// const storyBlokBaseUrl = 'https://api.storyblok.com/v1/cdn';

const storyBlokProductionAPIKey = process.env.REACT_APP_storyBlokProductionAPIKey;
const storyBlokPreviewAPIKey = process.env.REACT_APP_storyBlokPreviewAPIKey;
const storyBlokBaseUrl = process.env.REACT_APP_storyBlokBaseUrl;

const StoryBlokContext = React.createContext({
    slug: null,
    sbToken: null,
    isInEditor: false,
    useDraft: false,
    cv: null,
    pages: null,
    page: null,
    stories: null,
    story: null
});
export default StoryBlokContext;

export const GetStoryById = async (storyId, setStory, setErr404, abortController, sbToken) => {
    const storyUrl = `${storyBlokBaseUrl}/stories/${storyId}?find_by=uuid&token=${sbToken}`;

    try {
        const res = await fetch(storyUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
        if (res.ok && !abortController.signal.abort) {
            const data = await res.json();
            setStory && setStory(data.story);
            setErr404 && setErr404(!data.story);
        } else {
            setStory && setStory(null)
        }
        return;
    } catch (err) {
        console.error(`Error ${err} trying to retrieve ${storyId}`);
        setStory && setStory(null)
    }
};

export const GetStoryBySlug = async (fullSlug, setStory, setErr404, abortController, sbCtxt) => {
    const storyUrl = `${storyBlokBaseUrl}/stories/${fullSlug}?version=${sbCtxt.useDraft ? `draft` : `published`}&token=${
        sbCtxt.sbToken
    }&cv=${sbCtxt.cv}`;

    try {
        const res = await fetch(storyUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if (res.ok && !abortController.signal.abort) {
            const data = await res.json();
            setStory && setStory(data.story);
            setErr404 && setErr404(!data.story);
        } else {
            setStory && setStory(null)
        }
        return;
    } catch (err) {
        console.error(`Error ${err} trying to retrieve ${fullSlug}`);
        setErr404 && setErr404(true);
    }
};

export const StoryBlokInit = () => {
    const location = useLocation();
    const pUrl = location.pathname.split(/\/|$/);
    const slug = location.pathname === '/' ? 'home' : pUrl[pUrl.length] ? pUrl[pUrl.length] : pUrl[pUrl.length-1];

    const sbParams = new URLSearchParams(location.search);
    // console.log(`sb story id: ${sbParams.get("_storyblok")}`)
    // console.log(`sb story version: ${sbParams.get("_storyblok_version")}`)
    // console.log(`sb content type: ${sbParams.get("_storyblok_c")}`)
    // console.log(`sb space id: ${sbParams.get("_storyblok_tk[space_id]")}`)
    // console.log(`sb story timestamp: ${sbParams.get("_storyblok_tk[timestamp]")}`)
    // console.log(`sb story validation token: ${sbParams.get("_storyblok_tk[token]")}`)
    // console.log(`sb release id: ${sbParams.get("_storyblok_release")}`)
    // console.log(`sb language: ${sbParams.get("_storyblok_lang")}`)

    const useDraft = useRef(process?.env?.REACT_APP_storyBlokUseDraft && process.env.REACT_APP_storyBlokUseDraft.toLowerCase() === 'true');
    const inEditor = useRef(sbParams.get('_storyblok') !== null);

    const [sbCtxt, setSbCtxt] = useState({
        slug: null,
        sbToken: (inEditor.current || useDraft.current)
            ? storyBlokPreviewAPIKey
            : storyBlokProductionAPIKey,
        isInEditor: inEditor.current,
        useDraft: useDraft.current || inEditor.current,
        cv: null,
        pages: null,
        portfolios: null,
        articles: null,
        special_editions: null
    });

    const getAllStoriesByType = useCallback(
        async ({ type }) => {
            const compTypes = {
                articles: 'article',
                pages: 'page',
                portfolios: 'portfolio',
                special_editions: 'special_edition'
            };

            const sbStoriesUrl = `${storyBlokBaseUrl}/stories?starts_with=${type.toLowerCase()}/&sort_by=content.date:desc&token=${
                sbCtxt.sbToken
            }&version=${
                sbCtxt.useDraft ? 'draft' : 'published'
            }&resolve_relations=${compTypes[type]}.author&cv=${sbCtxt.cv}`;

            try {
                const data = await (
                    await fetch(sbStoriesUrl, {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json'
                        }
                    })
                ).json();
                if (type === 'articles')
                    setSbCtxt((sbCtxt) => {
                        return { ...sbCtxt, articles: data.stories };
                    });
                else if (type === 'pages')
                    setSbCtxt((sbCtxt) => {
                        return { ...sbCtxt, pages: data.stories };
                    });
                else if (type === 'portfolios')
                    setSbCtxt((sbCtxt) => {
                        return { ...sbCtxt, portfolios: data.stories };
                    });
                else if (type === 'special-editions')
                    setSbCtxt((sbCtxt) => {
                        return { ...sbCtxt, special_editions: data.stories };
                    });
            } catch (err) {
                console.error(`Error ${err} trying to retrieve stories`);
            }
        },
        [sbCtxt]
    );

    const GetStoryBlokSpaces = useCallback(async () => {
        try {
            const spacesUrl = `${storyBlokBaseUrl}/spaces/me?token=${sbCtxt.sbToken}`;
            const data = await (await fetch(spacesUrl, {})).json();
            setSbCtxt((sbCtxt) => {
                return { ...sbCtxt, cv: data.space.version };
            });
        } catch (err) {
            console.error(`Error ${err} fetching space info`);
        }
    }, [sbCtxt]);

    const initEventListeners = () => {
        window.storyblok.on(['change', 'published'], (payload) => {
            console.log(`change`, payload);
        });

        window.storyblok.on('input', (payload) => {
            const contentType = payload.story.full_slug.split(/\/|$/)[0];
            if (contentType === 'pages') {
                window.storyblok.resolveRelations(payload.story, 'page.author', () => {
                    setSbCtxt((sbCtxt) => {
                        const idx = sbCtxt.pages.findIndex(
                            (page) => page.id === payload.story.id
                        );
                        if (idx < 0) {
                            console.error(`page to update not found`);
                            return sbCtxt;
                        } else {
                            let newP = [...sbCtxt.pages];
                            newP[idx] = payload.story;
                            return { ...sbCtxt, pages: newP };
                        }
                    });
                });
            } else if (contentType === 'portfolios') {
                window.storyblok.resolveRelations(
                    payload.story,
                    'portfolio.author',
                    () => {
                        setSbCtxt((sbCtxt) => {
                            const idx = sbCtxt.portfolios.findIndex(
                                (portfolio) => portfolio.id === payload.story.id
                            );
                            if (idx < 0) {
                                console.error(`portfolio to update not found`);
                                return sbCtxt;
                            } else {
                                let newP = [...sbCtxt.portfolios];
                                newP[idx] = payload.story;
                                return { ...sbCtxt, portfolios: newP };
                            }
                        });
                    }
                );
            } else if (contentType === 'articles') {
                window.storyblok.resolveRelations(
                    payload.story,
                    'article.author',
                    () => {
                        setSbCtxt((sbCtxt) => {
                            const idx = sbCtxt.articles.findIndex(
                                (article) => article.id === payload.story.id
                            );
                            if (idx < 0) {
                                console.error(`article to update not found`);
                                return sbCtxt;
                            } else {
                                let newP = [...sbCtxt.articles];
                                newP[idx] = payload.story;
                                return { ...sbCtxt, articles: newP };
                            }
                        });
                    }
                );
            } else if (contentType === 'special-editions') {
                window.storyblok.resolveRelations(
                    payload.story,
                    'special_edition.author',
                    () => {
                        setSbCtxt((sbCtxt) => {
                            const idx = sbCtxt.special_editions.findIndex(
                                (special_edition) =>
                                    special_edition.id === payload.story.id
                            );
                            if (idx < 0) {
                                console.error(
                                    `special_edition to update not found`
                                );
                                return sbCtxt;
                            } else {
                                let newP = [...sbCtxt.special_editions];
                                newP[idx] = payload.story;
                                return { ...sbCtxt, special_editions: newP };
                            }
                        });
                    }
                );
            } else if (contentType === 'cta') {
                setSbCtxt(sbCtxt => {
                    return {...sbCtxt, story: payload.story}
                })
            }
        });
    };

    const addBridge = useCallback(
        (callback) => {
            const existingScript = document.getElementById('storyblokBridge');
            if (!existingScript) {
                const script = document.createElement('script');
                script.src = `https://app.storyblok.com/f/storyblok-latest.js?t=${sbCtxt.sbToken}`;
                script.id = 'storyblokBridge';
                document.body.appendChild(script);
                script.onload = () => {
                    callback();
                };
            } else {
                callback();
            }
        },
        [sbCtxt.sbToken]
    );

    useEffect(() => {
        if (!sbCtxt.isInEditor) return; // only load StoryBlok bridge if we're in the editor
        addBridge(initEventListeners); // first load the bridge, then initialize the event listeners
    }, [sbCtxt.isInEditor, addBridge]);

    useEffect(() => {
        if (sbCtxt.cv || !sbCtxt.sbToken) return;
        GetStoryBlokSpaces();
    }, [slug, sbCtxt.cv, sbCtxt.sbToken, GetStoryBlokSpaces]);

    useEffect(() => {
        setSbCtxt((sbCtxt) => {
            return {
                ...sbCtxt,
                slug: slug
            };
        });
    }, [slug]);

    const prevLocation = useRef(null);
    const newLocation = prevLocation.current !== location.pathname;

    useEffect(() => {
        if (!newLocation || !sbCtxt.cv) return;

        prevLocation.current = location.pathname;

        if (!sbCtxt.pages) getAllStoriesByType({ type: 'pages' });
        if (!sbCtxt.articles) getAllStoriesByType({ type: 'articles' });
        if (!sbCtxt.portfolios) getAllStoriesByType({ type: 'portfolios' });
        if (!sbCtxt.special_editions)
            getAllStoriesByType({ type: 'special-editions' });
    }, [
        newLocation,
        location.pathname,
        sbCtxt.useDraft,
        sbCtxt.cv,
        sbCtxt.pages,
        sbCtxt.articles,
        sbCtxt.portfolios,
        sbCtxt.special_editions,
        getAllStoriesByType
    ]);

    return sbCtxt;
};
