import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import './NotFound.scss';

const NotFound = () => {
    const {
        params: { slug }
    } = useRouteMatch('/:slug');
    let ourURL = window.location.href;

    const nfImg = `https://d37i5m3u8preqk.cloudfront.net/20150911-165957-_E3A1542-xlarge.jpg`;

    let msgTxt = slug === '404' ? 'you are looking for' : ourURL;

    useEffect(() => {
        document.title = `404 - Page Not Found | Walt Thirion`;
    }, [slug]);

    return (
        <div className="nf-page-content-container">
            <h1>Oops! We made a wrong turn and lost the trail!</h1>
            <section className="nf-page-content">
                <div className="nf-page-content-text">
                    <div className="nf-404">404 Error</div>
                    <p>
                        We are sorry but the page you are looking for does not
                        exist.
                    </p>
                    <p>
                        The page {msgTxt} might have been removed, had its name
                        changed, or is temporarily unavailable
                    </p>
                    <p>Please check the URL and try again.</p>
                    <p>
                        You can also return to my <a href="/">homepage</a> or
                        check out other useful pages.
                    </p>
                </div>
                <div className="nf-img-box">
                    <figure className="img-404">
                        <img src={nfImg} width="100%" alt='404' />
                        <figcaption className="portfolio-overlay-caption">
                            Hiking in Willis Creek
                        </figcaption>
                    </figure>
                </div>
            </section>
        </div>
    );
};

export default NotFound;
