import igLogo from './images/instagram_icon_bw.svg';
import liLogo from './images/linkedin_icon_bw.svg';
import fbLogo from './images/facebook_circle_icon.svg';
import './SocialIcons.scss';

const SocialIcons = () => {
    return (
        <p className="social-icons">
            Follow me on social media
                <a
                    href="https://instagram.com/waltthirion"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={igLogo} alt="Follow me on Instagram" />
                </a>
                <a
                    href="https://facebook.com/WaltThirionPhotography"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={fbLogo} alt="Follow Me on FaceBook" />
                </a>
                <a
                    href="https://linkedin.com/in/walt-thirion"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={liLogo} alt="Follow me on LinkedIn" />
                </a>
        </p>
    );
}

export default SocialIcons;