import React, { useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import StoryBlokContext, { StoryBlokInit } from './StoryBlokHooks';
import PortfolioContext, { usePortfolioData } from './PortfolioContext';
import Footer from './Footer';
import Header from './Header';
import './App.scss';


function App({children}) {
  const storyBlokData = StoryBlokInit();
  const portData = usePortfolioData();
  const location = useLocation();

  const isLandingPage = /^\/cta\//.test(location.pathname);

  useEffect(() => {
    // load google tag and recaptcha scripts

    const gaTag = window.location.hostname.toLowerCase() === 'waltthirion.com' ? process.env.REACT_APP_GA_TAG : process.env.REACT_APP_DEV_GA_TAG

    if (!document.getElementById('gsTag')) {
      const firstScript = document.getElementsByTagName('script')[0];
      const script = document.createElement('script');
      script.id = 'gsTag';
      script.src = `https://www.googletagmanager.com/gtm.js?id=${gaTag}`;
      script.async = true;
      firstScript.parentNode.insertBefore(script, firstScript);
    }
  }, [])

  return (
    <>
      <StoryBlokContext.Provider value={storyBlokData}>
        <PortfolioContext.Provider value={portData}>
          {!isLandingPage && (
            <>
              <Header className={'App-header'} />
              <main className="App-main-content">
                {children}
              </main>
              <Footer className={'App-footer'} />
            </>
          )}
          {isLandingPage && (
            <main className="App-main-content">
              {children}
            </main>
          )}
        </PortfolioContext.Provider>
      </StoryBlokContext.Provider>
    </>
  );
}

export default App;
