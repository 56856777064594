import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../Navigation';
import './header.scss';

const Header = ({className='App-header'}) => {
    return (
        <header className={className} >
            <div className="logo-block" >
                <div className="logo">
                    <Link to="/">
                        <span className="logo-first"><span className="logo-spacing">W</span>alt</span>{' '}
                        <span className="logo-last"><span className="logo-spacing-last">T</span>hirion</span>
                    </Link>
                </div>
                <div className="logo-tagline">
                    Photographer & Environmental Conservation Advocate
                </div>
            </div>
            <Navigation className={'App-primary-nav'} />
        </header>
    );
};

export default Header;
