import {NavLink} from 'react-router-dom';
import './NavButton.scss';

const NavButton = ({classN='nav-button', to, text=''}) => {
    return (
        <div className={classN}>
            <NavLink to={to}>
                {text}
            </NavLink>
        </div>
    )
}
export default NavButton;