import { useEffect } from 'react';

const NotFound = () => {
    useEffect(() => {
        window.location.href = `/404#${window.location.pathname}`;
    }, []);
    return null;
};

export default NotFound;
